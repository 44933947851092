
































import {Component, Prop, Vue} from 'vue-property-decorator';
import NorthStarService from "@/NorthStarService";
import {Board, NorthStar} from "@/typescript/types";
import WorkableItem from "@/components/general/WorkableItem.vue";


@Component({
  components: {WorkableItem},
})
export default class NorthStarBoardSelector extends Vue {
  isActive = false;
  selectedBoards: Array<Board> = [];
  service: NorthStarService | null = null;

  @Prop({default: undefined, required: true})
  northStar!: NorthStar;
  @Prop({default: [], required: true})
  linkedNorthstarBoards!: Array<Board>;

  get board() {
    return this.$store.state.board;
  }

  get boards() {

    return this.$store.state.workspace.boards;

  }

  get linkedBoards() {
    let boardCount = this.selectedBoards.length;
    if(boardCount > 0){
      let text = '';
      let boardText = this.selectedBoards.map((board: Board, i: any)=>{

        if(boardCount === i + 1 && boardCount != 1){
          return text+' & '+board.name;
        }else if(boardCount-1 === i + 1){
          return text+board.name;
        }else if(boardCount === 1){
          return text+board.name;
        }else{
          return text+board.name+', ';
        }

      }).join('');
      return boardCount+((boardCount > 1)?' rooms: ':' room: ')+ boardText;
    }

    return 'Rooms';

  }

  changed(board: any) {
    let index = this.selectedBoards.findIndex((room: any) => {
      return room.id === board.id;
    });

    if (index != -1) {
      this.selectedBoards.splice(index, 1);
    } else {
      this.selectedBoards.push(board);
    }

    this.service?.addBoardToNorthStar(this.selectedBoards, this.$props.northStar)
      .then((result: any) => {
        this.$events.fire('northstar_board_selected', result.data.updateNorthStar)
      })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'Changes saved!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }



  checkIfPresent(board: any) {
    return this.selectedBoards.some((room: any) => room.id === board.id);
  }

  mounted() {
    this.service = new NorthStarService(this.$apollo);
    this.selectedBoards = this.$props.linkedNorthstarBoards;
  }
}
