










import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class NorthStarPieChart extends Vue {
  delayedChecked: boolean = false;
  hasReachedHundred: boolean = false;
  defaultTotalValue: number = 100;

  @Prop({required: false, default: 100})
  progressBalancer!: number | null;

  @Prop({required: false, default: 20})
  progressPercentage!: number | null;

  get progressCompletionRate() {
    let current = this.progressPercentage;
    let total = this.progressBalancer;

    if(total && current) {
      let percentage = (total / current) * 100;
      return percentage.toFixed(0)
    } else {
      return 0;
    }
  }

}
