





































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DeleteMilestoneMutation, UpdateMilestoneMutation} from "@/graphql/NorthStar";
import {Milestone, Board as BoardObject} from "@/typescript/types";
import { WorkspaceQuery } from '@/graphql/workspace';
import store from "@/store";
import WorkableItem from "@/components/general/WorkableItem.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import ChatMessageService from "@/ChatMessageService";

@Component({
  components: {WorkableItem, ConfettiCanon},
})
export default class PageNotFound extends Vue {
  open: boolean = false;
  isCompleted: boolean = false;
  addRewardActive: boolean = false;
  editDescriptionActive: boolean = false;
  showMilestoneConfetti: boolean = false;
  status: string | null = 'not_started';
  description: string | null = null;
  reward: string | null = null;
  name: string | null = null;
  chatService: ChatMessageService | null = null;

  @Prop({required: false})
  color!: string | null;

  @Prop({required: false})
  orderIndex!: number | null;

  @Prop({default: undefined, required: true})
  milestone!: Milestone;

  
  @Prop({default: undefined, required: true})
  board!: BoardObject;

  mounted() {
    this.chatService = new ChatMessageService(this.$apollo);

    if(this.milestone) {
      this.status = this.milestone.status;
      this.name = this.milestone.name;
      this.reward = this.milestone.reward!;
      this.description = this.milestone.description!;
      if(this.milestone.status === 'completed') {
        this.isCompleted = true;
      }
    }
  }

  statusName(name: string) {
    if (name === 'not_started') {
      return 'Not started';
    } else if (name === 'in_progress') {
      return 'In progress';
    } else if (name === 'completed') {
      return 'Completed';
    } else if (name === 'cancelled') {
      return 'Cancelled';
    } else {
      return 'Undefined';
    }
  }

  setStatus(status: string) {
    this.status = status;
    this.isCompleted = status === 'completed';
    this.updateMilestone();
  }

  toggleStatus() {
    if(this.status === 'completed') {
      this.setStatus('not_started');
    } else {
      this.setStatus('completed')
    }
  }

  submitReward() {
    this.addRewardActive = false;
    this.updateMilestone();
  }

  submitDescriptionChange() {
    this.editDescriptionActive = false;
    this.updateMilestone();
  }

  triggerEditReward() {
    this.addRewardActive = true;
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.rewardInput.focus();
    });
  }

  triggerDescriptionChange() {
    this.open = true;
    this.editDescriptionActive = true;
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.descriptionInput.focus();
    });
  }

  updateMilestone() {
    this.$apollo
      .mutate({
        mutation: UpdateMilestoneMutation,
        variables: {
          id: this.milestone.id,
          name: this.name,
          description: this.description,
          status: this.status,
          reward: this.reward
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
      })
      .then(() => {
        this.$props.milestone!.status = this.status;
        this.$props.milestone!.name = this.name;
        this.$props.milestone!.description = this.description;
        this.$props.milestone!.reward = this.reward;
        this.$buefy.toast.open({
          message: 'Updated',
          position: 'is-bottom-right',
          type: 'is-black',
        });

   
        if (this.$props.milestone!.status === 'completed') {
          this.triggerMiletoneConfetti();
          if(this.$props.board) {
            this.chatService?.sendBoardMessage(this.$props.board.id, 'Great news! <b>'+this.$store.state.me.name+ '</b> marked the <b>"' + this.$props.milestone!.name + '"</b> milestone as completed! Well done! You are now one step closer to your goal! 💪');
          }
        }
       
      })
    ;
  }

  triggerMiletoneConfetti() {
    this.showMilestoneConfetti = true;
    setTimeout(() => {
      this.showMilestoneConfetti = false;
    }, 5000); // Stop showing confetti after 5 seconds
  }

  deleteMilestone() {
    this.openDeleteModal();
  }

  openDeleteModal() {
    this.$buefy.modal.open({
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete this goal?'
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.milestoneDeleteConfirmed();
          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  milestoneDeleteConfirmed(){
    this.$apollo
      .mutate({
        mutation: DeleteMilestoneMutation,
        variables: {
          id: this.milestone.id,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid }}]
      })
      .then(() => {
        this.$emit('milestone-deleted', this.milestone.id);
        this.$buefy.toast.open({
          message: 'Deleted',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }

  removeReward() {
    this.reward = null;
    if(this.milestone.reward){
      this.updateMilestone();
    }

  }
}
