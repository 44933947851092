import {DollarApollo} from "vue-apollo/types/vue-apollo";
import {BoardQuery, CreateBoardMessageMutation} from "@/graphql/board";

class ChatMessageService {

  private apollo: DollarApollo<any>;

  constructor(apollo: DollarApollo<any>) {
    this.apollo = apollo;
  }

  sendBoardMessage(boardId: string, message: string, files: Array<any> = []){
    return this.apollo.mutate({
      mutation: CreateBoardMessageMutation,
      variables: {
        id: boardId,
        message: message,
        files: files
      },
      refetchQueries:[{query: BoardQuery, variables:{id: boardId} }]
    }).then((result) => {
      return result.data.createBoardMessage;
    })
  }


}

export default ChatMessageService;