




























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Formatting from "@/components/chat/Formatting.vue";

@Component({
  components: {Formatting},
})
export default class UpdateNorthStar extends Vue {
  nsDescription: string | null = null;
  nsName: string | null = null;

  @Prop({required: true})
  description!: string;

  @Prop({required: true})
  name!: string;

  get board() {
    return this.$store.state.board;
  }

  submitDescriptionChange() {
    //@ts-ignore
    this.nsDescription = this.$refs.goalDescription.innerHTML;
    this.updateNorthStar();
  }

  updateNorthStar() {
    this.$emit('name', this.nsName);
    this.$emit('description', this.nsDescription);
    this.$emit('data', {
      name: this.nsName,
      description: this.nsDescription,
    });
    //@ts-ignore
    this.$parent.close();
  }

  applyFormat(type: string) {
    // @ts-ignore
    document.execCommand(type);
    // @ts-ignore
    let e = document.createEvent('HTMLEvents');
    // @ts-ignore
    e.initEvent('input', true, true);
    // @ts-ignore
    this.$refs.oDescription.dispatchEvent(e);
  }

  mounted() {
    if(this.description) {
      this.nsDescription = this.description;
    }
    if(this.name) {
      this.nsName = this.name;
    }
    if(document.querySelector('#goalDescription') !== null) {
      // @ts-ignore
      document.querySelector('#goalDescription').addEventListener("paste", function (e) {
        e.preventDefault();
        // @ts-ignore
        let text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
      });
    }
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.goalDescription.innerHTML = this.nsDescription;
      //@ts-ignore ToDo: fix type issue
      this.$refs.goalDescription.focus();
    });
  }
}
